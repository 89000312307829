<template>
  <div class="case">
    <caseProjectList 
    :listData='caseList'
    :listType='listType'
    @routerPush='routerPush'
    @createCaseProject='createCaseProject'
    @query='query'
    :queryList='queryList'
    @upDate='upLoadListQuery'
    :topForm='caseTopForm'
    @myTopFormSelectChange='myTopFormSelectChange'
    :caseListLoading='caseListLoading'
    ></caseProjectList>
    <div class="pageNation">
      <el-pagination small 
      @size-change="handleSizeChange" 
      @current-change="handleCurrentChange"
        :current-page="currentPage" 
        :page-sizes="pageSizes" 
        :page-size="pageSize"
        layout="total,sizes,prev,pager,next" 
        :total="total">
      </el-pagination>
    </div>
    <myDialog 
    :title='oppTitle'
    :isShow='oppIsShowCase'
    :closeDialog='closeDialogCase'
    >
      <div>
        <div class="radioGroup" v-show="isShowRadioGroup">
          <el-radio-group v-model="radio" @change='changeRadio'>
            <el-radio 
            v-for='item in caseType' :key='item.key' 
            :label="item.key">{{item.value}}</el-radio>
          </el-radio-group>
        </div>
        <div class="formBaseInfo" v-show="isShowFormBaseInfo">
          <div style='border-bottom:1px solid #eee'>
            <party ref='party'></party>
          </div>
          <div>
            <party ref='party2' title='对方当事人'></party>
          </div>
          <div class="lawyerBox">
            <div class="itemLawyer">
              <div class="titleItem">案件名称</div>
              <div class="inputItem">
                <el-input v-model="caseName" placeholder="请输入案件名称" size='small'></el-input>
              </div>
            </div>
            <div class="itemLawyer" style='align-items:flex-start;'>
              <div class="titleItem" style='padding-top: 13px;'>经办律师</div>
              <div class="inputItem">
                <lawyerParty ref='lawyerParty'></lawyerParty>
              </div>
            </div>
          </div>
          <div class="belongBox">
            <div class="caseBelong">
              <div class="text">案件归属</div>
              <div class="select">
                <el-select size='small' style="width: 100px;"
                v-model="beLongOptionsVal" placeholder="请选择">
                  <el-option
                    v-for="item in currentId"
                    :key="item.keyL"
                    :label="item.value"
                    :value="item.keyL">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="caseBelong">
              <div class="text">案源客户</div>
              <div class="select">
                <el-select size='small' style="width: 100px;"
                v-model="customOptionsVal" placeholder="请选择">
                  <el-option
                    v-for="item in customList"
                    :key="item.keyL"
                    :label="item.value"
                    :value="item.keyL">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="bottomBox">
            <el-button type='primary' size='small' @click='saveCreateCase'>完成创建</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 申请立案 -->
     <myDialog
    parentRef='isShowCaseOpp'
    title='申请立案'
    :isShow='isShowCaseOpp'
    :closeDialog='closeDialog'>
      <div class="projectOpp caseOpp">
        <div class="itemFlex">
          <div class="title">案件名称</div>
          <div class="titleVal">{{form.caseName}}</div>
        </div>
        <div class="itemFlex">
          <div class="title">立案信息</div>
          <div class="titleVal">
            <div class="itemFlex">
              <div class="title">案件类型</div>
              <div class="titleVal">{{form.caseTypeName}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">委托时间</div>
              <div class="titleVal">{{form.entrustDate}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">我方当事人</div>
              <div class="titleVal">
                <div class="dsrBox" v-for='item in form.ourLitigantList' :key='item.holderId'>
                  <div class="name title">{{item.holderName}}</div>
                  <div class="name idntify">{{item.holderTypeName}}</div>
                  <div class="name type">{{item.partyTypeName}}</div>
                </div>
              </div>
            </div>
            <div class="itemFlex">
              <div class="title">对方当事人</div>
              <div class="titleVal">
                <div class="dsrBox" v-for='item in form.oppositeLitigantList' :key='item.holderId'>
                  <div class="name title">{{item.holderName}}</div>
                  <div class="name idntify">{{item.holderTypeName}}</div>
                  <div class="name type">{{item.partyTypeName}}</div>
                </div>
              </div>
            </div>
            <div class="itemFlex">
              <div class="title">标的</div>
              <div class="titleVal">{{form.subject}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">标的金额</div>
              <div class="titleVal">{{form.subjectAmount}}元</div>
            </div>
            <div class="itemFlex">
              <div class="title">事实与理由</div>
              <div class="titleVal">{{form.factualReason}}</div>
            </div>
          </div>
        </div>
        <el-form ref='applyCaseForm'
        :model="applyCaseForm" label-width="70px">
        <el-form-item label="附件" prop="fileIdList" 
          :rules="[
            {required:true, trigger:'change',message:'附件不能为空'}
          ]">
            <el-upload :disabled='uploadLoading'
              ref='myUpload'
              class="uploadComp"
              action="#"
              :limit='5'
              :http-request='myUploadSubmit'
              :on-exceed='onExceed'
              :before-remove="beforeRemove"
              accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <el-button size="small" type="primary" 
              icon="el-icon-plus" :loading="uploadLoading">上传</el-button>
              <template #tip>
                <div class="errorBox" v-show='upLoadIsError'>
                  <el-alert
                    :title="errorText"
                    type="error"
                    show-icon
                    @close='closeAlert'>
                  </el-alert>
                </div>
                <div class="textLine">
                  <div>可上传<span>5个附件</span></div>
                  <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                </div>
              </template>
            </el-upload>
        </el-form-item>
        <div class="formBox">
          <el-form-item label="审批人" prop="reviewUserId"
            :rules="[
              {
                required: true,  trigger: 'change',message:'审批人不能为空'
              }
            ]">
              <el-select style='width:315px' size='small' 
              v-model="applyCaseForm.reviewUserId"
              filterable
                remote
                reserve-keyword
                placeholder="请输入审批人"
                :remote-method="remoteMethodspr"
                :loading="sprloading">
                <el-option
                  v-for="item in reviewUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="applyRemark">
              <el-input style='width:315px' size='small' type='textarea' 
              v-model="applyCaseForm.applyRemark" placeholder="请输入备注信息"></el-input>
            </el-form-item>
          <div class="formBottomBtn">
            <el-button type='primary' size='small'
            @click='submitApplyCase'>提交申请</el-button>
          </div>
        </div>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent,onMounted,ref } from 'vue';
import {useStore} from 'vuex';
import caseProjectList from '@/components/caseProjectList.vue';
import myDialog from '@/components/myDialog.vue';
import party from '@/components/party.vue';
import lawyerParty from '@/components/lawyerParty.vue';
import {casePage,saveCaseBasicInfo,customerSelect,getLanderUserSelect,finish,deleteCase,uploadFile,
getCaseInfo,saveApply} from '@/api';
import {PAGE_SIZE, PAGE_SIZES} from '@/utils/const.js';
import {ElMessage} from 'element-plus';
// 新建案件
const getCaseType = (store)=>{
  const caseType = ref([]);
  const customList = ref([]);
  const currentId = ref([]);
  const caseTypeData = async ()=>{
    return await store.dispatch('getDataDictionary',10003);
  };
  const getCustom = async ()=>{
    return await customerSelect()
  }
  // 当前登录人的租户id
  const getCurrentId = async ()=>{
    return await getLanderUserSelect();
  }
  onMounted(async ()=>{
    caseType.value = await caseTypeData();
    let res = await getCustom();
    if(res&&res.code==200){
      customList.value = res.data
    };
    let currentbeLoingsId = await getCurrentId();
    if(currentbeLoingsId&&currentbeLoingsId.code==200){
      currentId.value = currentbeLoingsId.data
    };
  })
  return {
    caseType,
    customList,
    currentId
  }
};

export default defineComponent({
  name:"case",
  components:{
    caseProjectList,
    myDialog,
    party,
    lawyerParty
  },
  setup(){
    const store = useStore();
    const {caseType,customList,currentId} = getCaseType(store);
    return {
      caseType,customList,currentId,
    }
  },
  async created(){
    let res = await this.getCaseList({
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        caseParam:this.caseParam,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      },{
        headers:{
          'SELECT-TENANT-ID':'ALL'
        }
      });
    this.upLoadList(res);
    this.getCaseTopForm();
  },
  data(){
    return {
      form:{},
      relId:'',
      uploadLoading:false,
      applyCaseForm:{
        fileIdList:[],
        reviewUserId:'',
        applyRemark:''
      },
      isShowCaseOpp:false,
      oppIsShowCase:false,
      zuhu:'ALL',
      topCaseType:'',
      caseStatus:'',
      caseParam:'',
      listType:'case',
      caseList:{
        data:[
          // {
          //   id:1,
          //   date:'2021-01-12',
          //   order:'苏永（专）2021 第 101 号',
          //   title:'张三入室盗窃张三入室盗窃杀人案张三入室盗窃杀人案杀人案',
          //   classType:'刑事辩护',
          //   lawyer:'张律师',
          //   status:'进行中'
          // },
        ]
      },
      customOptionsVal:'',
      beLongOptionsVal:'',
      caseName:'',
      currentPage:1,
      total:1,
      pageSize:PAGE_SIZE,
      pageSizes:PAGE_SIZES,
      queryList:[],
      caseTopForm:[],
      radio:'',
      isShowRadioGroup:false,
      isShowFormBaseInfo:false,
      oppTitle:'请选择案件类型',
      upLoadIsError:false,
      errorText:'',
      sprloading:false,
      getReviewUserList2:[
        {
          label:'测试写死',
          value:666
        }
      ],
      reviewUserList:[
        {
          label:'测试写死',
          value:666
        }
      ],
      caseListLoading:false
    }
  },
  methods:{
    async getCaseInfo(){
      let res = await getCaseInfo({caseId:this.relId});
      if(res&&res.code == 200){
        this.mapFormToData(res.data)
      }
    },
    async mapFormToData(newV){
      this.form.applyInfo = newV.applyInfo;
      this.form.applyInfo.applyStatusName = await this.$store.dispatch('getDictionaryName',{
        id:10009,
        status:newV.applyInfo.status
      });
      // 案件名称
      this.form.caseName = newV.caseName;
      // 案号
      this.form.caseNo = newV.caseNo;
      // 状态
      this.form.caseStatus = newV.caseStatus;
      // 类型
      this.form.caseType = newV.caseType;
      this.form.caseTypeName = await this.$store.dispatch('getDictionaryName',{
        id:10003,
        status:newV.caseType
      });
      // 书记员名称
      this.form.clerkName = newV.clerkName;
      // 书记员电话
      this.form.clerkTel = newV.clerkTel;
      // 法院地址
      this.form.courtAddr = newV.courtAddr;
      // 法院案号
      this.form.courtCaseNo = newV.courtCaseNo;
      // 法院城市
      this.form.courtCity = newV.courtCity;
      // 庭审级别
      this.form.courtLevel = newV.courtLevel;
      // 法院名称
      this.form.courtName = newV.courtName;
      // 法院所属省份
      this.form.courtProvince = newV.courtProvince;
      // 法院所属区
      this.form.courtTown = newV.courtTown;
      // 案源客户id
      this.form.customerId = newV.customerId;
      // 委托日期
      this.form.entrustDate = newV.entrustDate;
      // 预估金额
      this.form.estimatedAmount = newV.estimatedAmount;
      // 事实理由
      this.form.factualReason = newV.factualReason;
      // 费用备注
      this.form.feeRemark = newV.feeRemark;
      // 收费方式
      this.form.feeType = newV.feeType;
      // 案件发生日期
      this.form.happenDate = newV.happenDate;
      // 看守所详细地址
      this.form.jailAddr = newV.jailAddr;
      // 看守所所属城市
      this.form.jailCity = newV.jailCity;
      // 看守所联系人电话
      this.form.jailContactTel = newV.jailContactTel;
      // 看守所联系人
      this.form.jailContacter = newV.jailContacter;
      // 看守所名称
      this.form.jailName = newV.jailName;
      // 看守所所属省份
      this.form.jailProvince = newV.jailProvince;
      // 看守所所属区
      this.form.jailTown = newV.jailTown;
      // 律师团队备注
      this.form.lawyerTeamRemark = newV.lawyerTeamRemark;
      // 派出所详细地址
      this.form.policeOfficeAddr = newV.policeOfficeAddr;
      // 派出所名称
      this.form.policeOfficeName = newV.policeOfficeName;
      // 派出所所属城市
      this.form.policeOfficeCity = newV.policeOfficeCity;
      // 派出所联系人
      this.form.policeOfficeContacter = newV.policeOfficeContacter;
      // 派出所所属省份
      this.form.policeOfficeProvince = newV.policeOfficeProvince;
      // 派出所联系人电话
      this.form.policeOfficeTel = newV.policeOfficeTel;
      // 派出所所属区
      this.form.policeOfficeTown = newV.policeOfficeTown;
      // 主审法官名称
      this.form.presidingJudgeName = newV.presidingJudgeName;
      // 主审法官电话
      this.form.presidingJudgeTel = newV.presidingJudgeTel;
      // 检察院详细地址
      this.form.procuratorateAddr = newV.procuratorateAddr;
      // 检察院所属城市
      this.form.procuratorateCity = newV.procuratorateCity;
      // 检察院联系人
      this.form.procuratorateContacter = newV.procuratorateContacter;
      // 检察院名称
      this.form.procuratorateName = newV.procuratorateName;
      // 检察院所属省份
      this.form.procuratorateProvince = newV.procuratorateProvince;
      // 检察院联系人电话
      this.form.procuratorateTel = newV.procuratorateTel;
      // 检察院所属区
      this.form.procuratorateTown = newV.procuratorateTown;
      // 费用预计收款日期
      this.form.feeReceiptDate = newV.feeReceiptDate;
      // 争议标的
      this.form.subject = newV.subject;
      // 标的金额
      this.form.subjectAmount = newV.subjectAmount;
      // 我方当事人
      this.form.ourLitigantList = newV.ourLitigantList;
      // 对方当事人
      this.form.oppositeLitigantList = newV.oppositeLitigantList
    },
    // 列表模板相关
    async finishApi(data){
      this.caseListLoading = true;
      let res = await finish(data);
      if(res&&res.code==200){
        ElMessage({
          type:"success",
          message:"结案成功"
        })
      };
    this.caseListLoading = false;
  },
  async deletCase(caseId){
    this.caseListLoading = true;
    let res = await deleteCase({
      caseId
    });
    if(res&&res.code==200){
      ElMessage({
        type:"success",
        message:'删除成功'
      });
    };
    this.caseListLoading = false;
  },
    routerPush(val){
      console.log('xx:',val)
      let type = val.path;
      switch(type){
        case '/case/main' :
          this.$router.push({
            path:`/case/main/details/${val.id}`,
          })
          break;
        case '/project/main' :
          this.$router.push(type)
          break;
        case 'copy' :
          
          break;
        case 'isOver' :
          // 结案
          this.finishApi({
            caseId:val.id,
            caseStatus:2
          })
          break;
        case 'create' :
          // 立案
          this.relId = val.id;
          this.isShowCaseOpp = true;
          this.getCaseInfo()
          break;
        case 'delet' :
          // 案件删除
          this.deletCase(val.id)
          break;
      }
  },
    submitApplyCase(){
      this.$refs['applyCaseForm'].validate(v=>{
        if(v){
          this.saveApplyApi({
            applyRemark:this.applyCaseForm.applyRemark,
            applyType:2,
            fileIdList:this.applyCaseForm.fileIdList,
            relId:this.relId,
            relType:2,
            reviewUserId:this.applyCaseForm.reviewUserId
          },'isShowCaseOpp');
        }
      })
    },
    async saveApplyApi(data,type){
      let res = await saveApply(data);
      if(res&&res.code==200){
        this.closeDialog(type)
        this.$message({
          type:"success",
          message:"成功"
        })
      }
    },
    remoteMethodspr(v){
      this.sprloading = true;
      if(this.getReviewUserList2.length){
        this.reviewUserList = this.getReviewUserList2.filter(item=>{
          return item.userName.includes(v)
        });
        this.sprloading = false;
      }else{
        this.reviewUserList = [];
        this.sprloading = false;
      }
    },
    closeAlert(){
      console.log('closeAlert')
      this.errorText = '';
    },
    beforeRemove(file,fileList) {
      if(this.applyCaseForm.fileIdList.length === fileList.length){
        let currentUid = file.raw.uid;
        let currentIdx = fileList.filter((item,idx) => {
          if(item.raw.uid === currentUid){
            return idx
          }else{
            return 'none'
          }
        });
        if(currentIdx.length&&currentIdx[0]!=='none'){
          this.applyCaseForm.fileIdList.splice(currentIdx,1)
        }
      };
      return true
    },
    onExceed(){
      // console.log('onExceed')
      this.errorText = '最多只能上传5个附件';
      this.upLoadIsError = true;
    },
    myUploadSubmit(item){
      this.uploadFileApi(item.file)
    },
    // 文件上传
    async uploadFileApi(file){
      this.uploadLoading = true;
      let formData = new FormData()
      formData.append('file',file)
      formData.append("filepath",'lian')
      let res = await uploadFile(formData);
      if(res&&res.code==200){
        this.applyCaseForm.fileIdList.push(res.data.fileId)
        this.$message({
          type:'success',
          message:'上传成功'
        })
      }
      this.uploadLoading = false;
    },
    closeDialog(type){
      if(type){
        switch(type){
            // 立案申请
          case 'isShowCaseOpp' : 
            this.$refs['applyCaseForm'].resetFields();
            this.isShowCaseOpp = false; 
            break;
        };
        this.reviewUserList = [];
        this.applyCaseForm.fileIdList = [];
        
      }
    },
    createCaseProject(v){
      this.oppIsShowCase = true;
      this.isShowRadioGroup = true;
    },
    changeRadio(){
      this.isShowRadioGroup = false;
      this.oppTitle = '填写案件基本信息';
      this.isShowFormBaseInfo = true;
    },
    closeDialogCase(){
      this.oppIsShowCase = false;
      this.isShowRadioGroup = true;
      this.isShowFormBaseInfo = false;
      this.radio = '';
      this.beLongOptionsVal = '';
      this.customOptionsVal = '';
      this.caseName = ''
    },
    async myTopFormSelectChange(v){
      let {name,key} = v;
      let res;
      switch(name){
        case '状态':
          if(key==='ALL'){
            this.caseStatus = '';
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:'',
              caseType:this.topCaseType
            },{
              headers:{
                'SELECT-TENANT-ID':this.zuhu
              }
            });
            this.upLoadList(res);
          }else{
            this.caseStatus = key;
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:key,
              caseType:this.topCaseType
            },{
              headers:{
                'SELECT-TENANT-ID':this.zuhu
              }
            });
            this.upLoadList(res);
          }
          break;
        case '类型':
          if(key==='ALL'){
            this.topCaseType = '';
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:this.caseStatus,
              caseType:''
            },{
              headers:{
                'SELECT-TENANT-ID':this.zuhu
              }
            });
            this.upLoadList(res);
          }else{
            this.topCaseType = key;
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:this.caseStatus,
              caseType:key
            },{
              headers:{
                'SELECT-TENANT-ID':this.zuhu
              }
            });
            this.upLoadList(res);
          }
          break;
        case '归属':
          if(key==='ALL'){
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:this.caseStatus,
              caseType:this.topCaseType
            },{
              headers:{
                'SELECT-TENANT-ID':'ALL'
              }
            });
            this.upLoadList(res);
          }else{
            this.zuhu = key;
            res = await this.getCaseList({
              pageNo:this.currentPage,
              pageSize:this.pageSize,
              caseParam:this.caseParam,
              caseStatus:this.caseStatus,
              caseType:this.topCaseType
            },{
              headers:{
                'SELECT-TENANT-ID':key
              }
            });
            this.upLoadList(res);
          }
          break;
      }
    },
    async getCaseTopForm(){
      let topArr = [];
      // 状态
      let selectStatus = await this.$store.dispatch('getDataDictionary',10009);
      topArr.push({
        name:'状态',
        selectVal:'ALL',
        options:[{
          key: "ALL",
          value: "全部"
        },...selectStatus]
      });
      // 类型
      let selectType = await this.$store.dispatch('getDataDictionary',10003);
      topArr.push({
        name:'类型',
        selectVal:'ALL',
        options:[{
          key: "ALL",
          value: "全部"
        },...selectType]
      });
      // 归属
      let selectBelong = await getLanderUserSelect();
      let belongArr = [];
      if(selectBelong&&selectBelong.code==200){
        belongArr = selectBelong.data
      };
      belongArr.forEach(item=>{
        item.key = item.keyL
      })
      topArr.push({
        name:'归属',
        selectVal:'ALL',
        options:[{
          key:'ALL',
          keyL: "ALL",
          value: "全部"
        },...belongArr]
      });
      this.caseTopForm = topArr
    },
    async saveCreateCase(){
      // 提交的数据字段
      // let formDada = {
      //   caseName:'',
      //   caseNo:'',
      //   caseType:'',
      //   customerId:'',
      //   entrustDate:'',
      //   factualReason:'',
      //   happenDate:'',
      //   oppositeLitigantList:[],
      //   ourLitigantList:[],
      //   relLawyerList:[],
      //   subject:'',
      //   subjectAmount:''
      // };

      // 校验各个组件必填项
      let checkValidate;
      let checkValidate2;
      let checkValidate3;
      this.$refs['party'].checkIsRequire((v)=>{
        checkValidate = v
      });
      this.$refs['party2'].checkIsRequire((v)=>{
        checkValidate2 = v
      });
      this.$refs['lawyerParty'].checkIsRequire((v)=>{
        checkValidate3 = v
      })
      if(checkValidate&&checkValidate2&&checkValidate3){
        let formDada = {
          caseName:'',
          caseType:'',
          oppositeLitigantList:[],
          ourLitigantList:[],
          relLawyerList:[],
          customerId:''
        };
        // 案件类型
        formDada.caseType = this.radio;
        // 我方当事人
        // console.log(this.$refs['party'].ruleForm.formList)
        this.$refs['party'].ruleForm.formList.forEach(item=>{
          formDada.ourLitigantList.push({
            holderName:item.nameinput,
            holderType:item.type,
            partyType:item.identify,
            createCustomerFlag:item.isCreatedCustom?1:0
          })
        })
        // 对方当事人
        // console.log(this.$refs['party2'].ruleForm.formList)
        this.$refs['party2'].ruleForm.formList.forEach(item=>{
          formDada.oppositeLitigantList.push({
            holderName:item.nameinput,
            holderType:item.type,
            partyType:item.identify
          })
        })
        // 我方和对方当事人比对
        let conflictIdx;
        formDada.ourLitigantList.forEach((ourItem,idx)=>{
          formDada.oppositeLitigantList.forEach(item=>{
            if(item.holderName == ourItem.holderName){
              conflictIdx = {
                idx,
                ourItem
              }
            }
          })
        });
        if(conflictIdx&&conflictIdx.ourItem){
          ElMessage.warning({
            message: '我方和对方当事人不能一样',
            type: 'warning'
          });
          return
        };
        // 案件名称
        if(!this.caseName){
          let caseType = '';
          this.caseType.forEach(item=>{
            if(this.caseRadio==item.key){
              caseType = item.value
            }
          });
          this.caseName = `${formDada.ourLitigantList[0].holderName}和${formDada.oppositeLitigantList[0].holderName}的${caseType}案`;
        };
        formDada.caseName = this.caseName;
        // 经办律师
        // console.log(this.$refs['lawyerParty'].ruleForm.formList)
        this.$refs['lawyerParty'].ruleForm.formList.forEach(item=>{
          formDada.relLawyerList.push({
              lawyerName:item.nameinput,
              lawyerType:item.type,
              lawyerUserId:item.keyL?item.keyL:''
            })
        });
        // 案件归属
        // console.log(this.beLongOptionsVal)
        this.$store.commit('getCurrentbeLoingsId',this.beLongOptionsVal);
        // 案源客户
        formDada.customerId = this.customOptionsVal;
        let res = await saveCaseBasicInfo(formDada);
        if(res&&res.code==200){
          ElMessage.success({
            message: '创建成功',
            type: 'success'
          });
          this.closeDialogCase();
          let reslist = await this.getCaseList({
          pageNo:this.currentPage,
          pageSize:this.pageSize,
          caseParam:this.caseParam,
          caseStatus:this.caseStatus,
          caseType:this.topCaseType
        },{
          headers:{
            'SELECT-TENANT-ID':'ALL'
          }
        });
      this.upLoadList(reslist);
        }
      }else{
        return
      }
    },
    async upLoadListQuery(v){
      if(!v){
        this.caseParam = '';
        let res = await this.getCaseList({
          pageNo:this.currentPage,
          pageSize:this.pageSize,
          caseParam:this.caseParam,
          caseStatus:this.caseStatus,
          caseType:this.topCaseType
        },{
          headers:{
            'SELECT-TENANT-ID':this.zuhu
          }
        });
        this.upLoadList(res)
        return
      };
      let currentArr = this.queryList.filter(item=>{
        return item.caseId === v
      });
      this.caseParam = currentArr[0].caseName;
      let res = await this.getCaseList({
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        caseParam:this.caseParam,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      },{
        headers:{
          'SELECT-TENANT-ID':this.zuhu
        }
      });
      this.upLoadList(res)
    },
    async query(v){
      let res = await this.getCaseList({
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        caseParam:v,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      },{
        headers:{
          'SELECT-TENANT-ID':this.zuhu
        }
      });
      if(res&&res.code==200){
        let caseNameList = [];
        res.data.records.forEach(item=>{
          caseNameList.push({
            caseName:item.caseName,
            caseId:item.caseId
          })
        });
        this.queryList = caseNameList
      }
    },
    async handleSizeChange(v){
      this.pageSize = v;
      let res = await this.getCaseList({
        pageNo:this.currentPage,
        pageSize:v,
        caseParam:this.caseParam,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      },{
        headers:{
          'SELECT-TENANT-ID':this.zuhu
        }
      })
      this.upLoadList(res)
    },
    async handleCurrentChange(v){
      this.currentPage = v;
      let res = await this.getCaseList({
        pageNo:v,
        pageSize:this.pageSize,
        caseParam:this.caseParam,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      },{
        headers:{
          'SELECT-TENANT-ID':this.zuhu
        }
      })
      this.upLoadList(res)
    },
    async getCaseList(data,headerConfig){
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        caseParam:this.caseParam,
        caseStatus:this.caseStatus,
        caseType:this.topCaseType
      };
      let query;
      if(data){
        query = data;
      }else{
        query = params;
      }
      return await casePage(query,headerConfig)
    },
    upLoadList(res){
      if(res&&res.code==200){
        this.total = res.data.total * 1;
        let currentArr = [];
        res.data.records.forEach(item=>{
          currentArr.push({
            id:item.caseId,
            date:item.createDate.substring(0,10),
            order:item.caseNo?item.caseNo:'',
            title:item.caseName,
            classType:item.caseTypeName,
            lawyer:item.lawyerName,
            status:item.caseStatus==2?'已完成':'进行中'
          })
        });
        this.caseList.data = [...currentArr];
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.radioGroup{
  padding-bottom:30px;
  .el-radio-group{
    display: flex;
    align-items: center;
    width: 480px;
    padding-left: 45px;
    flex-wrap: wrap;
    margin: auto;
    .el-radio{
      width:33%;
      margin-right: 0;
      margin-top:25px;
    }
  }
}
.formBaseInfo{
  .lawyerBox{
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    width:100%;
    box-sizing: border-box;
    padding:30px 30px 0 30px;
    // overflow-y: scroll;
    // height: 155px;
    .itemLawyer{
      display: flex;
      align-items:center;
      margin-bottom:20px;
      .titleItem{
        font-size: 14px;
        color: #666;
      }
      .inputItem{
        width:420px;
        margin-left: 10px;
      }
    }
  }
  .belongBox{
    
    padding-top:30px;
    width:486px;
    padding-left:30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .caseBelong{
      display: flex;
      align-items: center;
      margin-right: 40px;
      &:last-child{
        margin: 0;
      }
      .text{
        font-size: 14px;
        color:#666;
        margin-right: 10px;
      }
    }
  }
  .bottomBox{
    padding:30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pageNation{
  width:1200px;
  margin:30px auto 0 auto;
  text-align: right;
}
.projectOpp{
  padding:30px;
  .itemFlex{
    display: flex;
    align-items: flex-start;
    margin-bottom:50px;
    .title{
      font-size: 14px;
      color:#666;
      margin-right: 14px;
    }
    .titleVal{
      font-size: 14px;
      color:#333;
      .title{
        width:65px
      }
      .itemFlex{
        margin-bottom:20px
      }
    }
  }
  .formBottomBtn{
    display: flex;
    justify-content: center;
  }
}
.applySeal{
  padding:30px;
  .flexCenter{
    ::v-deep{
      .el-form-item__label{
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .inlineBox{
    display: flex;
    justify-content: space-between;
  }
  .uploadComp{
    .textLine{
      margin-top:10px;
      div{
        font-size: 12px;
        color:#666;
        height:24px;
        line-height: 24px;
        span{
          color:$main-color;
        }
      }
    }
    .errorBox{
      .el-alert{
        padding:0 8px;
      }
    }
  }
  .formBottomBtn{
    display: flex;
    justify-content: flex-end;
  }
}
.caseOpp{
  .itemFlex{
    .titleVal{
      .itemFlex{
        .title{
          width:80px;
        }
      }
    }
  }
  .dsrBox{
    display: flex;
    margin-bottom: 20px;
    .title{
      width:100px;
    }
    .idntify{
      width:40px;
      margin:0 20px;
    }
  }
  .uploadComp{
    .textLine{
      margin-top:10px;
      div{
        font-size: 12px;
        color:#666;
        height:24px;
        line-height: 24px;
        span{
          color:$main-color;
        }
      }
    }
    .errorBox{
      .el-alert{
        padding:0 8px;
      }
    }
  }
}
</style>